<template>
  <div id="employee-new-modal">
    <vs-popup class="popup60" :title="title" :active.sync="localShow">

      <!-- ADD POPUP -->
      <vs-popup class="popup50" title="Cadastrar Serviço de Manutenção" :active.sync="storeServiceMaintenanceModal">
        <div class="vx-row">
          <div class="vx-col md:w-3/4 w-full mt-0">
            <vs-input class="w-full mt-0" label="Serviço" v-model="service.service_description" data-vv-as="Serviço" v-validate.initial="'required'" name="service_description" />
            <span class="text-danger text-sm" v-show="errors.has('service_description')">{{ errors.first('service_description') }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mt-0">
            <label class="vs-input--label">Valor</label>
            <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
                v-model="service.value"
                :currency="{prefix: 'R$ '}"
                :valueAsInteger="false"
                :distractionFree="false"
                :precision="2"
                :autoDecimalMode="true"
                :valueRange="{ min: 0 }"
                :allowNegative="false" />
          </div>
        </div>

        <vs-divider></vs-divider>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="save" :disabled="!validateForm">Cadastrar</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="storeServiceMaintenanceModal = false">Cancelar</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>


      <!-- EDIT POPUP -->
      <vs-popup class="popup50" title="Editar Serviço de Manutenção" :active.sync="editServiceMaintenanceModal">
        <div class="vx-row">
          <div class="vx-col md:w-3/4 w-full mt-0">
            <vs-input class="w-full mt-0" label="Serviço" v-model="service.service_description" data-vv-as="Serviço" v-validate.initial="'required'" name="service_description" />
            <span class="text-danger text-sm" v-show="errors.has('service_description')">{{ errors.first('service_description') }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mt-0">
            <label class="vs-input--label">Valor</label>
            <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
                v-model="service.value"
                :currency="{prefix: 'R$ '}"
                :valueAsInteger="false"
                :distractionFree="false"
                :precision="2"
                :autoDecimalMode="true"
                :valueRange="{ min: 0 }"
                :allowNegative="false" />
          </div>
        </div>

        <vs-divider></vs-divider>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="update" :disabled="!validateForm">Alterar</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="editServiceMaintenanceModal = false">Cancelar</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ allData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : allData.total }} de {{ allData.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu style="z-index:999999">
              <vs-dropdown-item @click="paginationSetPageSize(12)">
                <span>12</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca por serviço..." />

          <vx-tooltip text="Cadastrar Serviço" position="top" class="ml-1 mb-4 md:mb-0">
            <feather-icon @click="add" icon="PlusCircleIcon" svgClasses="h-8 w-8 cursor-pointer hover:text-primary"></feather-icon>
          </vx-tooltip>

      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :localeText="localeText"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :cacheBlockSize="payload.limit"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="cancel">Cancelar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import _ from 'lodash'
import { CurrencyInput }        from 'vue-currency-input'

import { AgGridVue }            from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { localePt_Br }          from '@/assets/agGridTranslate/locale.pt_Br'
// Cell Renderer
import CellRendererActions      from './cell-renderer/CellRendererActions.vue'

import moduleServiceMaintenance from '@/store/service-maintenance/moduleServiceMaintenance.js'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    vSelect,
    CurrencyInput,
    AgGridVue,
    CellRendererActions
  },
  props: {
    show: {
      Boolean, default: false
    },
    title: {
      String, default: 'Diálogo'
    }
  },

  computed: {
    serviceMaintenance () {
      return this.$store.state.serviceMaintenance.serviceMaintenance
    },
    validateForm () {
      return !this.errors.any()
    },
    allData () {
      return this.$store.state.serviceMaintenance.serviceMaintenancesGrid
    }
  },

  data () {
    return {
      localShow: false,
      editServiceMaintenanceModal: false,
      storeServiceMaintenanceModal: false,

      service: {
        service_description: null,
        value: 0
      },

      searchQuery: '',

      payload: {
        offset: 0,
        limit: 40,
        searchQuery: null,
        params: null,
        consultApi: true
      },

      // AgGrid
      localeText: null,
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'Serviço',
          field: 'service_description',
          width: 250
        },
        {
          headerName: 'Valor',
          field: 'value',
          valueFormatter: params => {
            return params.value ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'
          },
          width: 180
        },
        {
          headerName: 'Ações',
          field: 'actions',
          width: 95,
          sortable: false,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            edit: this.edit, // usado para passar parametros para dentro o componente renderizado no ag grid
            delete: this.confirmDelete // usado para passar parametros para dentro o componente renderizado no ag grid
          }
        }
      ],
      components: {
        CellRendererActions
      }
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.$emit('cancel')
      } else {
        // this.$store.dispatch('typePayment/fetchAll').then(() => { }).catch(err => { console.error(err) })
        // this.$store.dispatch('account/fetchAll').then(() => { }).catch(err => { console.error(err) })
        // this.$store.dispatch('accountPlan/fetchAll').then(() => { }).catch(err => { console.error(err) })
      }
    }
  },
  methods: {
    action () {
      this.$emit('action')
    },
    cancel () {
      this.$emit('cancel')
    },
    add () {
      this.service = {
        service_description: null,
        value: 0
      }
      this.storeServiceMaintenanceModal = true
    },
    edit (data) {
      this.$store.commit('serviceMaintenance/SET', data.data)
      this.service = JSON.parse(JSON.stringify(this.serviceMaintenance))
      this.editServiceMaintenanceModal = true
    },
    confirmDelete (data) {
      this.$store.commit('serviceMaintenance/SET', data.data)
      console.log(this.serviceMaintenance)
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: `Tem certeza que deseja excluir \n "${data.data.service_description}"?`,
        accept: this.delete,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    delete () {
      this.$store.dispatch('serviceMaintenance/delete', this.serviceMaintenance.id)
        .then(()   => {
          this.refreshGrid()
          this.showDeleteSuccess()
        })
        .catch(err => { console.error(err) })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'SUCESSO',
        text: 'Dados Excluídos',
        iconPack: 'feather',
        icon: 'icon-check'
      })
    },
    refreshGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.setDatasource(this.dataSource)
      // this.gridApi.refreshInfiniteCache() // REMOVI ESSE PQ QDO NÃO HÁ NENHUM REGISTRO NÃO ATUALIZA AO CADASTRAR O PRIMEIRO
    },
    refreshInfiniteCacheGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.refreshInfiniteCache()
    },
    getSearchResult (searchVal) {
      this.payload.searchQuery = searchVal
      this.gridApi.setDatasource(this.dataSource)
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    onGridReady (params) {
      this.gridApi = params.api
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAllData () {
      this.gridApi.showLoadingOverlay()
      const data = await this.$store.dispatch('serviceMaintenance/fetchGrid', this.payload)
      this.gridApi.hideOverlay()
      if (!data.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return data
    },
    async save () {
      try {
        this.$vs.loading()
        await this.$store.dispatch('serviceMaintenance/store', this.service)
        this.$vs.loading.close()
        this.refreshInfiniteCacheGrid()
        this.storeServiceMaintenanceModal = false
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    async update () {
      try {
        this.$vs.loading()
        await this.$store.dispatch('serviceMaintenance/update', this.service)
        this.$vs.loading.close()
        this.refreshInfiniteCacheGrid()
        this.editServiceMaintenanceModal = false
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  mounted () {
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.setGrid('allData')
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)

    if (!moduleServiceMaintenance.isRegistered) {
      this.$store.registerModule('serviceMaintenance', moduleServiceMaintenance)
      moduleServiceMaintenance.isRegistered = true
    }
    this.$store.dispatch('serviceMaintenance/fetchAll').then(() => { }).catch(err => { console.error(err) })
  }
}
</script>

<style>
  hr {
    margin-top: 2px;
    border: none;
    height: 1px;
    /* Set the hr color */
    color: rgb(204, 204, 204); /* old IE */
    background-color: rgb(204, 204, 204); /* Modern Browsers */
  }

  .popup60 .vs-popup {
    width: 60% !important;
  }

  .vue_select_drop_size_160 .vs__dropdown-menu {
    max-height: 160px;
  }
  .vs-tooltip {
    z-index: 99999 !important;
  }
</style>
