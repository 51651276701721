import state from './moduleServiceMaintenanceState.js'
import mutations from './moduleServiceMaintenanceMutations.js'
import actions from './moduleServiceMaintenanceActions.js'
import getters from './moduleServiceMaintenanceGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

