var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "employee-new-modal" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup60",
          attrs: { title: _vm.title, active: _vm.localShow },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c(
            "vs-popup",
            {
              staticClass: "popup50",
              attrs: {
                title: "Cadastrar Serviço de Manutenção",
                active: _vm.storeServiceMaintenanceModal,
              },
              on: {
                "update:active": function ($event) {
                  _vm.storeServiceMaintenanceModal = $event
                },
              },
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-3/4 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        label: "Serviço",
                        "data-vv-as": "Serviço",
                        name: "service_description",
                      },
                      model: {
                        value: _vm.service.service_description,
                        callback: function ($$v) {
                          _vm.$set(_vm.service, "service_description", $$v)
                        },
                        expression: "service.service_description",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("service_description"),
                            expression: "errors.has('service_description')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("service_description")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Valor"),
                    ]),
                    _c("currency-input", {
                      staticClass:
                        "w-full vs-inputx vs-input--input normal hasValue",
                      attrs: {
                        currency: { prefix: "R$ " },
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        autoDecimalMode: true,
                        valueRange: { min: 0 },
                        allowNegative: false,
                      },
                      model: {
                        value: _vm.service.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.service, "value", $$v)
                        },
                        expression: "service.value",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("vs-divider"),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-4 flex flex-wrap items-center justify-end",
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-auto mt-2",
                          attrs: { disabled: !_vm.validateForm },
                          on: { click: _vm.save },
                        },
                        [_vm._v("Cadastrar")]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-4 mt-2",
                          attrs: { type: "border", color: "secondary" },
                          on: {
                            click: function ($event) {
                              _vm.storeServiceMaintenanceModal = false
                            },
                          },
                        },
                        [_vm._v("Cancelar")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "vs-popup",
            {
              staticClass: "popup50",
              attrs: {
                title: "Editar Serviço de Manutenção",
                active: _vm.editServiceMaintenanceModal,
              },
              on: {
                "update:active": function ($event) {
                  _vm.editServiceMaintenanceModal = $event
                },
              },
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-3/4 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        label: "Serviço",
                        "data-vv-as": "Serviço",
                        name: "service_description",
                      },
                      model: {
                        value: _vm.service.service_description,
                        callback: function ($$v) {
                          _vm.$set(_vm.service, "service_description", $$v)
                        },
                        expression: "service.service_description",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("service_description"),
                            expression: "errors.has('service_description')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("service_description")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Valor"),
                    ]),
                    _c("currency-input", {
                      staticClass:
                        "w-full vs-inputx vs-input--input normal hasValue",
                      attrs: {
                        currency: { prefix: "R$ " },
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        autoDecimalMode: true,
                        valueRange: { min: 0 },
                        allowNegative: false,
                      },
                      model: {
                        value: _vm.service.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.service, "value", $$v)
                        },
                        expression: "service.value",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("vs-divider"),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-4 flex flex-wrap items-center justify-end",
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-auto mt-2",
                          attrs: { disabled: !_vm.validateForm },
                          on: { click: _vm.update },
                        },
                        [_vm._v("Alterar")]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-4 mt-2",
                          attrs: { type: "border", color: "secondary" },
                          on: {
                            click: function ($event) {
                              _vm.editServiceMaintenanceModal = false
                            },
                          },
                        },
                        [_vm._v("Cancelar")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center" },
            [
              _c(
                "div",
                { staticClass: "flex-grow" },
                [
                  _c(
                    "vs-dropdown",
                    {
                      staticClass: "cursor-pointer",
                      attrs: { "vs-trigger-click": "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                        },
                        [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currentPage * _vm.paginationPageSize -
                                  (_vm.paginationPageSize - 1)
                              ) +
                                " - " +
                                _vm._s(
                                  _vm.allData.total -
                                    _vm.currentPage * _vm.paginationPageSize >
                                    0
                                    ? _vm.currentPage * _vm.paginationPageSize
                                    : _vm.allData.total
                                ) +
                                " de " +
                                _vm._s(_vm.allData.total)
                            ),
                          ]),
                          _c("feather-icon", {
                            attrs: {
                              icon: "ChevronDownIcon",
                              svgClasses: "h-4 w-4",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-dropdown-menu",
                        { staticStyle: { "z-index": "999999" } },
                        [
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.paginationSetPageSize(12)
                                },
                              },
                            },
                            [_c("span", [_vm._v("12")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.paginationSetPageSize(20)
                                },
                              },
                            },
                            [_c("span", [_vm._v("20")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.paginationSetPageSize(30)
                                },
                              },
                            },
                            [_c("span", [_vm._v("30")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("vs-input", {
                staticClass:
                  "sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4",
                attrs: { placeholder: "Busca por serviço..." },
                on: { input: _vm.updateSearchQuery },
                model: {
                  value: _vm.searchQuery,
                  callback: function ($$v) {
                    _vm.searchQuery = $$v
                  },
                  expression: "searchQuery",
                },
              }),
              _c(
                "vx-tooltip",
                {
                  staticClass: "ml-1 mb-4 md:mb-0",
                  attrs: { text: "Cadastrar Serviço", position: "top" },
                },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: "PlusCircleIcon",
                      svgClasses: "h-8 w-8 cursor-pointer hover:text-primary",
                    },
                    on: { click: _vm.add },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
            attrs: {
              components: _vm.components,
              gridOptions: _vm.gridOptions,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
              overlayNoRowsTemplate: _vm.noRowsTemplate,
              localeText: _vm.localeText,
              rowSelection: "multiple",
              colResizeDefault: "shift",
              animateRows: true,
              pagination: true,
              paginationPageSize: _vm.paginationPageSize,
              cacheBlockSize: _vm.payload.limit,
              suppressPaginationPanel: true,
              enableRtl: _vm.$vs.rtl,
            },
            on: { "grid-ready": _vm.onGridReady },
          }),
          _c("vs-pagination", {
            attrs: { total: _vm.totalPages, max: 10 },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "secondary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }