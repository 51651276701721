export default {
  serviceMaintenances: [],
  serviceMaintenance: {},
  serviceMaintenancesGrid: {
    data: [],
    total: 0
  }


}
