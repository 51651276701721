import Vue from 'vue'

export default {
  SET (state, payload) {
    state.serviceMaintenance = Object.assign({}, state.serviceMaintenance, payload) //precisa ser assim para manter a reatividade
  },
  REFRESH (state, payload) {
    state.serviceMaintenances = payload
  },
  SET_SERVICE_MAINTENANCES_GRID (state, payload) {
    state.serviceMaintenancesGrid = payload
  },
  STORE (state, payload) {
    state.serviceMaintenances.push(payload) //precisa ser assim para manter a reatividade
    state.serviceMaintenances.sort((a, b) => { return a.service_description.localeCompare(b.service_description) }) // Ordena por nome

    state.serviceMaintenancesGrid.data.push(payload)
    state.serviceMaintenancesGrid.data.sort((a, b) => { return a.service_description.localeCompare(b.service_description) }) // Ordena por nome
    state.serviceMaintenancesGrid.total = state.serviceMaintenancesGrid.total + 1
  },
  UPDATE (state, payload) {
    const idx = state.serviceMaintenances.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.serviceMaintenances, idx, payload)

    const idx1 = state.serviceMaintenancesGrid.data.findIndex(o => o.id === payload.id)
    if (idx1 >= 0) Vue.set(state.serviceMaintenancesGrid.data, idx1, payload)
  },
  DELETE (state, payload) {
    const idx1 = state.serviceMaintenances.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.serviceMaintenances.splice(idx1, 1) //precisa ser assim para manter a reatividade

    const idx = state.serviceMaintenancesGrid.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.serviceMaintenancesGrid.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.serviceMaintenancesGrid.total = state.serviceMaintenancesGrid.total - 1
    }
  }

}
