export default {
  forSelect (state) {
    const serviceMaintenances = []
    for (const k in state.serviceMaintenances) {
      serviceMaintenances.push({
        label: state.serviceMaintenances[k].service_description,
        value: state.serviceMaintenances[k].id,
        data:  state.serviceMaintenances[k]
      })
    }
    return serviceMaintenances
  }
}
